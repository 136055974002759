import * as React from "react";
import * as styles from '../assets/styles/styles.module.css';
import Header from "../components/Header/Header";
import Navigation from "../components/Navigation/Navigation";
import Gallery from "../components/Gallery/Gallery";
import Footer from "../components/Footer/Footer";

const BooksPage = () => {
  return (
    <main>
      <Header />
      <div className={styles.wrapper}>
        <Navigation />
        <Gallery className={styles.content} type="inspiration"/>
      </div>
      <Footer />
    </main>
  )
}

export default BooksPage
